html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 50vmin;
  padding: 0 0 20px 0;
  pointer-events: none;
}

.App-end-display {
  height: 20vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-column-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-main {
  background-color: #282c34;
  min-height: 60px;
  font-size: calc(10px + 2vmin);
  color: white;
  height: calc(100% - calc(30px + 1vmin));
  padding: 10px;
  overflow-y: scroll;
}

.App-graph-container {
  position: relative;
  height: 48vh;
  width: 90vw;
}

.App-question {
  margin-top: 20px;
}

.App-footer {
  height: calc(30px + 1vmin);
  justify-content: center;
  display: flex;
  flex-direction: row;
  font-size: calc(4px + 1vmin);
  background-color: #2a383d;
  color: white;
}

.App-footer-element {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem 0 1.25rem;
  position: relative;
  border: 0 solid rgba(0,0,0,.125);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
